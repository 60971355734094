<template>
  <div class="schema-editor-with-preview">
    <SchemaEditor
      v-model="schema"
      :domain="selectedDomain"
      :domain-schemas="domainSchemas"
      :merged-schema="mergedSchema"
      :key-space="keySpace"
      :key-pattern="keyPattern"
      :disabled="disabled"
      :parent-type="parentType"
      :type-editable="typeEditable"
      :hide-name="hideName"
      :allowed-types="allowedTypes"
      :ext-view-mode="extViewMode"
      :class="{
        'show-preview': previewShown,
      }"
      @restore="$emit('restore')"
      @update:view="viewMode = $event"
    />

    <div
      v-if="isInEditMode"
      v-ripple
      class="preview-expand-btn"
      @click="showPreview = !showPreview"
    >
      <div class="toggle-icon-top">
        <v-icon color="primary">
          mdi-chevron-double-{{ showPreview ? "right" : "left" }}
        </v-icon>
      </div>
      <div class="preview-expand-title-container">
        <div class="preview-expand-title text-button primary--text">
          Schema merge preview
        </div>
      </div>
      <div class="toggle-icon-bottom">
        <v-icon color="primary">
          mdi-chevron-double-{{ showPreview ? "right" : "left" }}
        </v-icon>
      </div>
    </div>
    <v-expand-x-transition>
      <div v-show="previewShown && schema" class="schema-previewer-container">
        <SchemaMergePreviewer :schema-rule="schemaRule" :key="reloadPreview" />
      </div>
    </v-expand-x-transition>
  </div>
</template>

<script>
import SchemaEditor from "./SchemaEditor";
import SchemaMergePreviewer from "../preview/SchemaMergePreviewer";

export default {
  components: {
    SchemaEditor,
    SchemaMergePreviewer,
  },

  props: {
    value: {
      type: Object,
      required: false,
    },

    keySpace: {
      type: String,
      required: true,
    },

    keyPattern: {
      type: String,
      required: true,
    },

    //Defines the type of the parent schema.
    //Type "schema" is used for the root
    parentType: {
      type: String,
      required: false,
    },

    //All schemas from other domains and pattern from which the current schema is inheriting
    // only used for first entry, will then be split up into uimodel
    domainSchemas: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    //The merged root schema
    // only used for first entry, will then be split up into uimodel
    mergedSchema: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    //Defines, if the schema type may be edited
    typeEditable: {
      type: Boolean,
      required: false,
      default: true,
    },

    //Hides the name textfield
    hideName: {
      type: Boolean,
      required: false,
      default: false,
    },

    //An array of allowed schema types
    //will influence the possible types in the type selection
    allowedTypes: {
      type: Array,
      required: false,
      validator: (types) => {
        return Array.isArray(types);
      },
    },

    //set the view mode outside of the data editor
    //Will disable switching between modes via controls
    extViewMode: {
      type: String,
      required: false,
      validator: (mode) => {
        return ["view", "edit", "json"].includes(mode);
      },
    },
  },

  data() {
    return {
      schema: this.$cloneObject(this.value),
      reloadKey: 0,
      viewMode: this.extViewMode ?? "edit",
      showPreview: false,
      reloadPreview: 0,
    };
  },

  watch: {
    schema: {
      handler: function (schema) {
        this.reloadKey++;
        this.$emit("input", schema);
      },
      deep: true,
    },

    value: {
      handler: function (value) {
        if (this.$isEqual(value, this.schema)) return;
        this.schema = this.$cloneObject(value);
        this.reloadKey++;
      },
      deep: true,
    },

    previewShown(isShown) {
      if (isShown) {
        this.$nextTick(() => this.reloadPreview++);
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    schemaRule() {
      this.reloadKey;
      const domain = this.$store.state.domains.find(
        ({ id }) => this.selectedDomain === id
      );
      return {
        keySpace: this.keySpace,
        keyPattern: this.keyPattern,
        domainId: domain.id,
        domainPath: domain.fullPath,
        schema: this.schema,
      };
    },

    isInEditMode() {
      return this.viewMode !== "view" && this.extViewMode !== "view";
    },

    previewShown() {
      return this.isInEditMode && this.showPreview;
    },
  },
};
</script>

<style scoped>
.schema-editor-with-preview {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  height: 100%;
}

.schema-editor-with-preview > .preview-expand-btn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 4px;
  cursor: pointer;
  max-width: 28px;
}

.schema-editor-with-preview > .preview-expand-btn:hover,
.schema-editor-with-preview > .preview-expand-btn:focus {
  background: #eeeeee;
  cursor: pointer;
  outline: 0;
}

.schema-editor-with-preview > .preview-expand-btn > div {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.schema-editor-with-preview > .preview-expand-btn > .toggle-icon-bottom {
  align-items: flex-end;
}

.schema-editor-with-preview > .preview-expand-btn > .toggle-icon-top {
  align-items: flex-start;
}

.schema-editor-with-preview
  > .preview-expand-btn
  > .preview-expand-title-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.schema-editor-with-preview
  > .preview-expand-btn
  > .preview-expand-title-container
  > .preview-expand-title {
  display: flex;
  transform: rotate(270deg);
  white-space: nowrap;
}

.schema-editor-with-preview::v-deep > .schema-editor {
  margin: 0px;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  padding: 4px;
}

.schema-editor-with-preview::v-deep > .schema-editor.show-json {
  width: calc(100% - 32px);
}

.schema-editor-with-preview::v-deep > .schema-editor.show-json.show-preview {
  max-width: calc(60% - 32px);
}

.schema-editor-with-preview.show-preview::v-deep
  > .schema-editor:not(.show-reader) {
  max-width: 60%;
}

.schema-editor-with-preview::v-deep > .schema-editor > .items {
  height: calc(100% - 52px);
}

.schema-editor-with-preview::v-deep > .schema-editor > .items > div {
  height: 100%;
  overflow-y: scroll;
}

.schema-editor-with-preview::v-deep > .schema-editor > .json-schema-editor,
.schema-editor-with-preview::v-deep > .schema-editor > .schema-reader {
  margin-top: 12px;
  height: calc(100% - 76px);
  overflow-y: scroll;
}

.schema-editor-with-preview > .schema-previewer-container {
  margin: 0px;
  height: 100%;
  width: 60%;
  overflow-y: hidden;
}

.schema-editor-with-preview
  > .schema-previewer-container::v-deep
  > .schema-merge-previewer {
  height: 100%;
}
</style>