var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:{
    'navigation-drawer': true,
    'navigation-drawer-extended': !_vm.minified,
  },attrs:{"mini-variant":_vm.minified,"width":_vm.width,"mini-variant-width":_vm.expandOnHover ? 56 : 80,"permanent":"","data-test-id":"navigationDrawer"},on:{"update:miniVariant":function($event){_vm.minified=$event},"update:mini-variant":function($event){_vm.minified=$event}}},[_c('div',{staticClass:"navigation-drawer-content"},[_vm._t("header",[(_vm.title)?_c('v-list-item',[(_vm.titleIcon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.titleIcon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.subtitle))])],1)],1):_vm._e(),(_vm.title)?_c('v-divider'):_vm._e()]),(_vm.loadingItems)?_c('v-container',{staticClass:"loading-overlay"},[_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":_vm.minified ? 48 : 96,"color":"primary"}})],1)],1):_vm._t("items",[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":_vm.selectedIndex != undefined,"active-class":_vm.activeClass},model:{value:(_vm.selectedIndex),callback:function ($$v) {_vm.selectedIndex=$$v},expression:"selectedIndex"}},[_vm._l((_vm.items),function(item,index){return _vm._t("default",[_c('v-list-item',{class:{
                selected: _vm.isSelected(item, index),
                'v-list-item--active': _vm.isSelected(item, index),
              },attrs:{"data-test-id":'navigation_drawer_item_' + (item.id || index),"disabled":_vm.minified},on:{"click":function($event){return _vm.select(item, index)}}},[(item[_vm.itemIcon])?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item[_vm.itemIcon]))])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getItemName(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getItemName(item)))])])],1)],{"item":{ item: item }})})],2)],1)],{"items":_vm.items})],2),(!_vm.expandOnHover)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"navigation-drawer-expand-btn",on:{"click":function($event){_vm.minified = !_vm.minified}}},[(_vm.minified)?_c('v-icon',[_vm._v("mdi-chevron-double-right")]):_c('v-icon',[_vm._v("mdi-chevron-double-left")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }