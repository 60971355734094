<template>
  <v-navigation-drawer
    :mini-variant.sync="minified"
    :width="width"
    :mini-variant-width="expandOnHover ? 56 : 80"
    permanent
    :class="{
      'navigation-drawer': true,
      'navigation-drawer-extended': !minified,
    }"
    data-test-id="navigationDrawer"
  >
    <div class="navigation-drawer-content">
      <slot name="header">
        <v-list-item v-if="title">
          <v-list-item-icon v-if="titleIcon">
            <v-icon>{{ titleIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">{{ title }}</v-list-item-title>
            <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="title" />
      </slot>
      <v-container v-if="loadingItems" class="loading-overlay">
        <v-overlay>
          <v-progress-circular
            indeterminate
            :size="minified ? 48 : 96"
            color="primary"
          />
        </v-overlay>
      </v-container>
      <slot v-else name="items" v-bind:items="items">
        <v-list dense>
          <v-list-item-group
            v-model="selectedIndex"
            :mandatory="selectedIndex != undefined"
            :active-class="activeClass"
          >
            <!-- eslint-disable-next-line -->
            <slot v-for="(item, index) in items" v-bind:item="{ item }">
              <v-list-item
                :class="{
                  selected: isSelected(item, index),
                  'v-list-item--active': isSelected(item, index),
                }"
                :data-test-id="'navigation_drawer_item_' + (item.id || index)"
                :disabled="minified"
                @click="select(item, index)"
              >
                <v-list-item-icon v-if="item[itemIcon]">
                  <v-icon>{{ item[itemIcon] }}</v-icon>
                </v-list-item-icon>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-on="on" v-bind="attrs">
                      {{ getItemName(item) }}
                    </v-list-item-title>
                  </template>
                  <span>{{ getItemName(item) }}</span>
                </v-tooltip>
              </v-list-item>
            </slot>
          </v-list-item-group>
        </v-list>
      </slot>
    </div>
    <div
      v-if="!expandOnHover"
      v-ripple
      class="navigation-drawer-expand-btn"
      @click="minified = !minified"
    >
      <v-icon v-if="minified">mdi-chevron-double-right</v-icon>
      <v-icon v-else>mdi-chevron-double-left</v-icon>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: false,
    },

    items: {
      type: Array,
      required: true,
      validator: (items) => {
        return items.every((item) => item instanceof Object);
      },
    },

    title: {
      type: String,
      required: false,
    },

    subtitle: {
      type: String,
      required: false,
    },

    titleIcon: {
      type: String,
      required: false,
    },

    width: {
      type: Number,
      required: false,
      default: 400,
    },

    permanent: {
      type: Boolean,
      required: false,
      default: true,
    },

    minify: {
      type: Boolean,
      required: false,
      default: false,
    },

    expandOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },

    loadingItems: {
      type: Boolean,
      required: false,
      default: false,
    },

    itemKey: {
      type: String,
      required: false,
      default: "id",
    },

    itemName: {
      type: String,
      required: false,
      default: "name",
    },

    itemIcon: {
      type: String,
      required: false,
      default: "icon",
    },

    activeClass: {
      type: String,
      required: false,
      default: "psblue--text",
    },

    minifyOnItemClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      minified: false,
      selectedIndex: undefined,
      selectedItem: this.value,
    };
  },

  mounted() {
    if (this.selectedItem) {
      this.selectedIndex = this.items.findIndex((item) => {
        return item?.[this.itemKey] === this.selectedItem?.[this.itemKey];
      });
    }
  },

  watch: {
    minify(minify) {
      if (minify === this.minified) return;
      this.minified = minify;
    },

    minified(minified) {
      this.$emit("minified", minified);
    },

    value: {
      handler: function (value) {
        this.selectedItem = value;
        this.selectedIndex = this.items.findIndex((item) => {
          return item?.[this.itemKey] === value?.[this.itemKey];
        });
      },
      deep: true,
    },
  },

  methods: {
    select(item, index) {
      this.selectedIndex = index;
      this.selectedItem = item;
      if (this.minifyOnItemClick) this.minified = true;
      this.$emit("input", item);
    },

    getItemName(item) {
      return item?.[this.itemName];
    },

    isSelected(item) {
      return item?.[this.itemKey] === this.selectedItem?.[this.itemKey];
    },
  },
};
</script>

<style scoped>
.navigation-drawer::v-deep > .v-navigation-drawer__content {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.navigation-drawer::v-deep
  > .v-navigation-drawer__content
  > .navigation-drawer-expand-btn {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}

.navigation-drawer::v-deep
  > .v-navigation-drawer__content
  > .navigation-drawer-expand-btn:focus,
.navigation-drawer::v-deep
  > .v-navigation-drawer__content
  > .navigation-drawer-expand-btn:hover {
  background: #eeeeee;
  cursor: pointer;
  outline: 0;
}

.navigation-drawer::v-deep
  > .v-navigation-drawer__content
  > .navigation-drawer-content {
  width: calc(100% - 24px);
  overflow-y: auto;
  overflow-x: hidden;
}

.navigation-drawer::v-deep
  > .v-navigation-drawer__content
  > .navigation-drawer-content
  > .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.navigation-drawer::v-deep
  > .v-navigation-drawer__content
  > .navigation-drawer-content
  > .loading-overlay
  > .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
<style>
.navigation-drawer
  > .v-navigation-drawer__content
  > .navigation-drawer-content
  > .loading-overlay
  > .v-overlay--active
  > .v-overlay__scrim {
  opacity: 0 !important;
}
</style>