<template>
  <v-container
    fluid
    fill-height
    class="configuration-editor-container"
    data-test-id="configurationPage"
  >
    <!-- MAIN CONTENT -->
    <!-- Sidebar menu for table of content -->
    <NavigationDrawer
      :minify="minify"
      :items="sortedConfigurationTree"
      :loading-items="loadingKeys"
      :width="400"
      data-test-id="configurationMenu"
      @minified="(minified) => (minify = minified)"
    >
      <template #header>
        <v-icon v-if="minify" class="py-5 px-3"> mdi-magnify </v-icon>
        <div v-else class="d-flex align-center">
          <v-text-field
            v-model="search"
            dense
            outlined
            hide-details="auto"
            prepend-icon="mdi-magnify"
            placeholder="Search Configuration..."
            class="pa-3"
            clearable
            data-test-id="configurationTreeSearch"
          />
          <v-btn icon title="Open all nodes" @click="openAllTreeNodes(true)">
            <v-icon>mdi-expand-all</v-icon>
          </v-btn>
          <v-btn
            icon
            title="Collapse all nodes"
            @click="openAllTreeNodes(false)"
          >
            <v-icon>mdi-collapse-all</v-icon>
          </v-btn>
        </div>
        <v-divider class="mb-3" />
      </template>

      <template #items="{ items }">
        <v-icon v-show="minify" class="pa-3"> mdi-cogs </v-icon>
        <v-treeview
          v-show="!minify"
          dense
          hoverable
          return-object
          item-text="displayName"
          item-key="id"
          class="configuration-tree"
          data-test-id="configurationTree"
          :open-all="openAll"
          :key="treeKey"
          :items="items"
          :search="search"
          :filter="filterTree"
        >
          <template #prepend="{ item }">
            <v-icon
              v-if="hasConfiguration(item)"
              x-small
              :color="isSelectedKey(item) ? 'psblue' : 'grey'"
              @click="selectKey(item.keySpace, item)"
            >
              mdi-cog
            </v-icon>
          </template>

          <template #label="{ item }">
            <div
              :class="{
                'psblue--text font-weight-bold': isSelectedKey(item),
                'text-truncate': true,
                'font-weight-medium': item.children,
                'text-h6 py-2': item.name === 'root',
                editable: item.dataEditable || item.schemaEditable,
              }"
              :title="item.displayName"
              :data-test-id="item.name + '_config_item'"
              @click="
                (item.dataEditable || item.schemaEditable) &&
                  selectKey(item.keySpace, item)
              "
            >
              {{ item.displayName }}
            </div>
          </template>
        </v-treeview>
      </template>
    </NavigationDrawer>

    <div
      class="d-flex justify-start align-start"
      :style="{
        width: editorWidth + 'px',
        maxWidth: editorWidth + 'px',
        height: detailHeight + 'px',
      }"
    >
      <!-- Main frame for basic editors -->
      <Component
        v-if="!!selectedKey.component"
        v-bind="selectedKey.componentParameters"
        :is="selectedKey.component"
        :loading="loadingKeys"
      />
      <v-container
        fluid
        v-else-if="isConfigurationEditor"
        class="basic-editor-container"
      >
        <v-container v-if="loading" class="loading-overlay">
          <v-overlay>
            <v-progress-circular indeterminate size="96" color="primary" />
          </v-overlay>
        </v-container>
        <!-- TOOLBAR -->
        <v-toolbar
          v-if="!loading && hasSelectedKey"
          class="configuration-editor-toolbar"
        >
          <v-toolbar-title>{{ selectedKey.name }}</v-toolbar-title>
          <v-spacer />
          <div v-if="runningAction" class="px-4">
            <v-progress-circular
              indeterminate
              :size="24"
              :width="2"
              color="grey darken-3"
            />
          </div>
          <v-switch
            v-model="showSchema"
            v-if="selectedKey.schemaEditable && selectedKey.dataEditable"
            inset
            label="Edit Schema"
            hide-details
            class="edit-schema-btn"
            data-test-id="editSchemaSwitch"
            :disabled="runningAction"
          />
          <v-btn
            class="ml-2"
            data-test-id="saveConfigurationBtn"
            :outlined="!isDirty"
            :color="hasViolation ? 'error' : 'green'"
            :disabled="runningAction"
            @click="saveChanges()"
          >
            <ViolationAlert
              v-if="hasViolation"
              :violation="violation"
              :color="isDirty ? 'white' : 'error'"
              alignment="left"
            />
            Save
          </v-btn>
          <v-btn
            v-if="!showSchema && savedData"
            outlined
            :disabled="runningAction"
            color="red"
            class="ml-2"
            data-test-id="deleteConfigurationBtn"
            @click="remove"
          >
            Delete
          </v-btn>
        </v-toolbar>
        <v-form
          v-if="!loading && selectedKey.id"
          @submit.prevent
          ref="editorForm"
          :data-test-id="selectedKey.name + '_editor'"
        >
          <DataEditor
            v-if="!showSchema"
            v-model="selectedData"
            :saved-data="savedData"
            :merged-data="mergedData"
            :schema="mergedSchema"
            :property="selectedKey.name"
            :key-space="selectedKeySpace"
            :key-pattern="selectedKey.name"
            :disabled="runningAction"
            :key="selectedKey.id + editorKey"
            @restore="selectedData = $cloneObject(savedData)"
          />

          <SchemaEditorWithPreview
            v-else
            v-model="selectedSchema"
            :domain="selectedDomain"
            :domain-schemas="domainSchemas"
            :merged-schema="mergedSchema"
            :key-space="selectedKeySpace"
            :key-pattern="selectedKey.name"
            :disabled="runningAction"
            :key="selectedKey.id + editorKey"
            parent-type="schema"
            @restore="selectedSchema = $cloneObject(savedSchema)"
          />
        </v-form>
      </v-container>
      <ProductTypeEditor
        v-else-if="isProductTypeEditor && selectedKey.id"
        :product-type="selectedKey.id"
        :data-test-id="selectedKey.id + '_editor'"
      />
      <CacheOverview v-else-if="isCacheOverview" />
    </div>
  </v-container>
</template>

<script>
/*TODO: 
	- EDITABLE CHECK FOR KEY PATTERNS
		Currently we have to load all schema rules and their merged schemas to check for each if it is editable or not. 
		This could be improved by an endpoint, which returns all editable configuration keys
*/

import DataEditor from "./data/editors/DataEditor";
import SchemaEditorWithPreview from "./schema/editors/SchemaEditorWithPreview";
import ProductTypeEditor from "components/product-type/ProductTypeEditor";
import CacheOverview from "components/cache/CacheOverview";

import NavigationDrawer from "components/common/templates/NavigationDrawer";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import configServiceMixin from "mixins/config-service-mixin";
import mainOverviewMixin from "mixins/main-overview-mixin";

export default {
  components: {
    DataEditor,
    SchemaEditorWithPreview,
    ProductTypeEditor,
    CacheOverview,
    NavigationDrawer,
    ViolationAlert,
  },

  mixins: [configServiceMixin, mainOverviewMixin],

  data() {
    return {
      minify: false,
      runningAction: false,
      loading: false,
      loadingKeys: true,
      selectedSchema: undefined,
      savedSchema: undefined,
      mergedSchema: {},
      selectedData: undefined,
      savedData: undefined,
      mergedData: undefined,
      selectedKey: {},
      selectedKeySpace: null,
      spaces: null, //holds all information about configuration keySpaces and keys
      domainData: [],
      domainSchemas: [],
      showErrors: false,
      violation: null,
      showSchema: false,
      isDirty: false,
      configurationTree: [],
      search: "",
      treeKey: 0, //used to re-render the configuration treeview component
      openAll: true,
      loadConfigurationController: null,
      editorKey: 0,
      sources: [],
      hiddenKeyPatterns: [],
    };
  },

  provide() {
    //provide necessary methods for the data or schema editor components
    return {
      getPropertyData: this.getPropertyData, //provides access to the whole config data
      getViolations: this.getViolations,
      getSource: this.getSource,
    };
  },

  async mounted() {
    await this.init();
  },

  watch: {
    $route() {
      if (!this.$route.matched.some(({ name }) => name === "configuration")) {
        return;
      }
      this.init();
    },

    selectedData: {
      handler: function (data) {
        if (this.schowSchema) return;
        this.isDirty = !this.$isEqual(data, this.savedData) && data;
      },
      deep: true,
    },

    selectedSchema: {
      handler: function (schema) {
        if (!this.schowSchema) return;
        this.isDirty = !this.$isEqual(schema, this.savedSchema) && schema;
      },
      deep: true,
    },

    showSchema() {
      //reset errors
      this.violation = null;
    },

    search() {
      this.openAllTreeNodes(true);
    },

    isDirty(isDirty) {
      this.$set(this.$route.params, "unsavedChanges", isDirty);
    },
  },

  methods: {
    async init() {
      try {
        if (!this.spaces) {
          this.spaces = {};
          //initialize schema and data
          await this.loadSystemConfiguration();
          await this.loadProductTypes();
          this.createSettingsNode();
        }
        await this.openRouteKey();
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
        this.$router.push({
          name: "configuration",
        });
      } finally {
        this.loadingKeys = false;
      }
    },

    async openRouteKey() {
      const extensionRoute = this.getExtensionRoute();
      if (extensionRoute) {
        const extension = this.extensionKeys.find(
          ({ route }) => route === extensionRoute
        );
        const keySpace = extension.keySpace;
        const keyConfig = this.spaces[keySpace][extension.keyPattern];
        this.selectKey(keySpace, keyConfig);
      } else if (this.isProductTypeEditor) {
        const productType = this.$route.params.productType;
        const keyConfig = this.spaces["ProductType"][productType];
        this.selectKey("ProductType", keyConfig);
      } else if (this.isCacheOverview) {
        this.selectKey("Cache", this.spaces["Cache"]);
      } else if (this.isConfigurationEditor) {
        const key = this.$route.params.key;
        const keyConfig = this.spaces?.["Configuration"]?.[key];
        if (!keyConfig) {
          throw Error(
            "Configuration " +
              key +
              " could not be found in key space Configuration on domain " +
              this.selectedDomain
          );
        }
        this.loadKey("Configuration", keyConfig);
      }
    },

    createSettingsNode() {
      const tree = {
        id: this.$uuid.v4(),
        name: "root",
        displayName: "Settings",
        children: [],
      };

      const cacheKey = {
        id: "cache",
        name: "Cache",
        displayName: "Cache",
        keySpace: "Cache",
        dataEditable: true,
      };
      tree.children.push(cacheKey);
      this.$set(this.spaces, "Cache", cacheKey);
      this.configurationTree.push(tree);
    },

    async loadProductTypes() {
      try {
        /* Get the product types from the API */
        let productTypes = {};
        const tree = {
          id: this.$uuid.v4(),
          name: "root",
          displayName: "Product Types",
          children: [],
        };
        const keySpace = "ProductType";
        const allProductTypes =
          await this.$store.$coreApi.coreConfigurationApi.getProductTypes(
            this.selectedDomain
          );
        allProductTypes?.forEach?.((productType) => {
          //Build the tree nodes for each product type
          tree.children.push({
            id: productType,
            name: productType,
            displayName: productType,
            keySpace,
            dataEditable: true,
            schemaEditable: true,
          });

          this.$set(productTypes, productType, {
            id: productType,
            name: productType,
            keySpace,
          });
        });
        this.configurationTree.push(tree);
        this.$set(this.spaces, "ProductType", productTypes);
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Error when loading product types! Cause: " + e
        );
      }
    },

    async loadSystemConfiguration() {
      try {
        let spaces = {};
        const tree = {
          id: this.$uuid.v4(),
          name: "root",
          displayName: "System Configuration",
          children: [],
        };
        const keySpace = "Configuration";

        this.addExtensionsKeys(keySpace, spaces, tree);

        let configurationKeys =
          await this.$store.$coreApi.coreConfigurationApi.configLookup(
            this.selectedDomain,
            keySpace,
            "*",
            {
              lookupRegion: ["schema"],
            }
          );

        if (!Array.isArray(configurationKeys)) {
          configurationKeys = [];
        }
        const keySet = new Set(configurationKeys.map((item) => item.key));
        const allKeys = Array.from(keySet)
          .filter((key) => {
            return this.hiddenKeyPatterns.every(
              (hiddenKey) => !key.startsWith(hiddenKey)
            );
          })
          .sort((keyA, keyB) => {
            //order rules by levels of key pattern and
            //if same, sort alphabetically
            const countA = keyA.split(".").length;
            const countB = keyB.split(".").length;
            if (countA !== countB) {
              return countA - countB;
            }
            return keyA.localeCompare(keyB);
          });

        for (const keyPattern of allKeys) {
          //if there is already a key pattern with the same name and keySpace, check if its in the currently
          //selected domain. If not, skip it
          if (spaces?.[keySpace]?.[keyPattern]) continue;
          if (!spaces[keySpace]) this.$set(spaces, keySpace, {});
          let rule = {};
          try {
            //load merged schema for each key, so that it can be determined, if data/schema
            //of the key is editable
            rule =
              await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
                this.selectedDomain,
                keySpace,
                keyPattern,
                { localize: false }
              );
            const schema = rule?.schema;
            this.$set(
              rule,
              "schemaEditable",
              schema?.schemaUI?.editable ?? false
            );
            this.$set(rule, "dataEditable", schema?.dataUI?.editable ?? true);
          } catch (e) {
            console.warn(
              "Something went wrong when loading merged schema of " +
                keyPattern,
              e
            );
          }

          this.$set(rule, "id", this.$uuid.v4());
          const isEditable = rule?.schemaEditable || rule?.dataEditable;
          //only add the schema rule if it editable
          if (isEditable) {
            let dataLocation = rule?.dataLocation;
            const dataLocationKey = dataLocation?.keyPattern;

            if (
              !dataLocationKey ||
              dataLocationKey.endsWith("*") ||
              dataLocationKey.endsWith(">")
            ) {
              //if the data location is either a pattern or placeholder
              //use the original key pattern as data location
              dataLocation = { keySpace, keyPattern };
            }

            //Add data location and further information to the schema rule
            this.$set(rule, "name", keyPattern);
            this.$set(rule, "dataLocation", dataLocation);
            this.$set(rule, "domain", rule.domainId);
            this.$set(spaces[keySpace], keyPattern, rule);
            this.buildTreeNodes(keyPattern, rule, tree);

            if (dataLocationKey?.endsWith("*")) {
              //if the data location ends with an *, we have to lookup all keys which match this pattern and
              //add them to the configurations tree
              try {
                let lookupRes = [];
                lookupRes =
                  await this.$store.$coreApi.coreConfigurationApi.configLookup(
                    this.selectedDomain,
                    dataLocation.keySpace,
                    dataLocationKey,
                    {
                      lookupRegion: ["data"],
                      keyMatching: "completeKey",
                    }
                  );

                const pseudoRuleArray = lookupRes
                  .map((lookupItem) => {
                    return {
                      id: this.$uuid.v4(),
                      name: lookupItem.key,
                      keyPattern: lookupItem.key,
                      keySpace: lookupItem.keySpace,
                      specificity: lookupItem.specificity,
                      domain: lookupItem.domain?.id,
                      dataLocation: {
                        keySpace: lookupItem.keySpace,
                        keyPattern: lookupItem.key,
                      },
                      schema: rule?.schema,
                      schemaEditable: rule?.schemaEditable,
                      dataEditable: rule?.dataEditable,
                    };
                  })
                  .reduce((array, pseudoRule) => {
                    //filter out duplicates
                    let existingRule = array.find(
                      (item) => item.keyPattern === pseudoRule.keyPattern
                    );
                    if (!existingRule) {
                      array.push(pseudoRule);
                    } else if (
                      existingRule.specificity < pseudoRule.specificity
                    ) {
                      existingRule = pseudoRule;
                    }
                    return array;
                  }, []);

                pseudoRuleArray.forEach((pseudoRule) => {
                  this.$set(
                    spaces[keySpace],
                    pseudoRule.keyPattern,
                    pseudoRule
                  );
                  this.buildTreeNodes(pseudoRule.keyPattern, pseudoRule, tree);
                });
              } catch (e) {
                console.warn("Data location lookup failed", e);
              }
            }
          }
        }

        this.spaces = spaces;
        this.configurationTree.push(tree);
      } catch (e) {
        console.error(e);
        this.$store.commit(
          "SET_ERROR",
          "Error when loading system configuration! Cause: " + e
        );
      }
    },

    addExtensionsKeys(keySpace, spaces, tree) {
      //Add keys specified in @/extensions.js
      let hiddenKeys = [];
      this.extensionKeys
        .filter((extension) => {
          return extension.keySpace === keySpace;
        })
        .forEach((extension) => {
          const keyPattern = extension.keyPattern;
          if (spaces?.[keySpace]?.[keyPattern]) return;
          if (!spaces[keySpace]) this.$set(spaces, keySpace, {});

          const extensionRule = {
            id: this.$uuid.v4(),
            name: keyPattern,
            domain: this.selectedDomain,
            componentParameters: {},
            ...extension,
          };

          this.$set(spaces[keySpace], keyPattern, extensionRule);
          this.buildTreeNodes(keyPattern, extensionRule, tree);

          if (extension.hideChildren) {
            hiddenKeys.push(keyPattern);
          }
        });

      this.hiddenKeyPatterns = hiddenKeys;
    },

    buildTreeNodes(keyPattern, rule, tree) {
      /* Build the naviagtion menu tree
				* Take the current key pattern, a.b.c for example
					* Split it at the dot and create a tree node for each level, so the tree looks like this:
						- a
							- b
								- c
					* If a key a.b.xx comes along, the tree uses the existing structure and updates it like this:
						- a
							- b
								- c
								- xx
			*/
      keyPattern.split(".").reduce((obj, key, idx, keys) => {
        if (!obj.children) this.$set(obj, "children", []);
        const currentKey = keys.slice(0, idx + 1).join(".");
        //if there is already a tree node with the specified key
        //return it.
        const existing = obj.children.find(({ name }) => name === currentKey);
        if (existing) return existing;
        let child = {
          id: this.$uuid.v4(),
          name: currentKey,
          displayName: key,
        };

        if (currentKey === keyPattern) {
          //copy the schema rules properties into the node
          child = Object.assign({}, child, rule);
        }
        obj.children.push(child);
        return child;
      }, tree);
    },

    /**
     * Returns configuration data of a property specified in the params parameter
     * If the requested data is outside of the currently opened configuration, the configuration
     * will be loaded
     */
    async getPropertyData(params) {
      if (!params) return null;
      const sourceType = params.sourceType;
      const domain = params.domainId || this.selectedDomain;
      const path = params.path;
      let currentData = null;

      if (sourceType === "data" || sourceType === "schema") {
        let keySpace = params.keySpace;
        let key = params.key;

        if (key?.includes("<productType>")) {
          const parts = key.split(".");
          const idx = parts.findIndex((part) => part === "<productType>");
          const productType = this.selectedKey?.name?.split(".")?.[idx];
          key = key.replace("<productType>", productType);
        }

        //Retrieve the data of a certain config key
        if (key && keySpace) {
          //data is in a different key so call the API
          if (sourceType === "data") {
            const merged =
              await this.$store.$coreApi.coreConfigurationApi.getMergedData(
                domain,
                keySpace,
                key
              );
            currentData = merged?.data;
          } else {
            const merged =
              await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
                domain,
                keySpace,
                key
              );
            currentData = merged?.schema;
          }
        } else {
          if (sourceType === "data") {
            const preview =
              await this.$store.$coreApi.coreConfigurationApi.getPreviewMergedData(
                domain,
                this.selectedKey?.keySpace,
                this.selectedKey?.keyPattern,
                this.selectedData,
                this.mergedSchema
              );

            if (preview?.ok) {
              const data = await preview.json();
              currentData = data?.mergedData?.data;
            }
          } else {
            let schemaRule = this.$cloneObject(this.selectedKey);
            this.$set(schemaRule, "schema", this.selectedSchema);

            const preview =
              await this.$store.$coreApi.coreConfigurationApi.getPreviewMergedSchema(
                this.selectedDomain,
                this.selectedKey?.keyPattern,
                this.selectedKey?.keySpace,
                schemaRule
              );

            if (preview?.ok) {
              const response = await preview.json();
              currentData = response?.mergedSchema?.schema;
            }
          }
        }
      } else if (sourceType === "dimension") {
        //load the data of a product type dimension
        let productType = params.productType;
        const dimensionKey = params.dimensionKey;
        if (!productType) return null;
        if (productType === "<productType>") {
          //Get product type from currently selected key
          const parts = this.selectedKey?.name?.split(".");
          productType = parts?.[0];
        }

        if (dimensionKey) {
          currentData =
            await this.$store.$coreApi.coreConfigurationApi.getProductTypeDimensionOptions(
              domain,
              productType,
              dimensionKey
            );
        } else {
          currentData =
            await this.$store.$coreApi.coreConfigurationApi.getProductTypeDimensions(
              domain,
              productType
            );
        }
      }

      if (!currentData) return null;
      if (!path) return currentData;
      //traverse the given data according to the path and return the property value
      return this.$getObjectValueByPath(currentData, path);
    },

    async selectKey(keySpace, key) {
      if (this.selectedKey.id !== key.id) {
        const routeName = key.route;
        let route;

        if (routeName) {
          //Is extension route
          if (!this.$route.matched.some(({ name }) => name === routeName)) {
            route = {
              name: routeName,
            };
          }
        } else if (keySpace === "ProductType") {
          const productType = key.name;
          if (!this.isProductTypeEditor) {
            //select product type data tab per default,
            //if no product type editor context is set in the URL
            route = {
              name: "productTypeEditor",
              params: { productType },
            };
          }
          keySpace = "ProductType";
        } else if (keySpace === "Cache") {
          if (!this.isCacheOverview) {
            route = { name: "cache" };
          }
        } else {
          route = {
            name: "systemConfigEditor",
            params: { key: key.name },
          };
        }

        if (route && !this.$isEqual(route, this.$route)) {
          await this.$router.push(route);
        }
        this.selectedKeySpace = keySpace;
        this.selectedKey = key;
        return;
      }
    },

    async loadKey(keySpace, key = {}) {
      let aborted = false;
      try {
        if (!key.schemaEditable && !key.dataEditable) return;

        if (this.isExtensionKey(key.name)) {
          //Is extension, so do not load data but rather render
          //the given component
          this.selectKey(keySpace, key);
          return;
        }

        if (this.loading && this.loadConfigurationController) {
          this.loadConfigurationController.abort();
        }

        this.loading = true;

        //reset values
        this.selectedKey = key;
        this.selectedKeySpace = keySpace;
        this.selectedData = undefined;
        this.savedData = undefined;
        this.mergedData = undefined;
        this.mergedSchema = undefined;
        this.selectedSchema = undefined;
        this.savedSchema = undefined;
        this.showSchema = false;

        this.loadConfigurationController = new AbortController();

        if (key.id === "SKIPASS") {
          this.loading = false;
          return;
        }

        //load the schema of the selected key
        const schemaAborted = await this.loadSchema(
          keySpace,
          key.name,
          key.schemaEditable
        );

        let dataAborted = false;
        if (key.dataEditable) {
          //load the data of the selected key
          const dataLocation = key.dataLocation;
          dataAborted = await this.loadData(
            dataLocation.keySpace,
            dataLocation.keyPattern,
            key.dataEditable
          );
        } else {
          //if the data is not editable show the schema
          this.showSchema = true;
        }

        aborted = schemaAborted || dataAborted;

        //reset errors
        this.violation = null;
      } catch (e) {
        this.$store.commit("SET_ERROR", "Error when loading key! Cause: " + e);
      } finally {
        if (!aborted) this.loading = false;
      }
    },

    isExtensionKey(keyPattern) {
      return this.extensionKeys.some(
        (extension) => extension.keyPattern === keyPattern
      );
    },

    async loadSchema(keySpace, keyPattern, schemaEditable) {
      //load the localized merged schema
      const merged =
        await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
          this.selectedDomain,
          keySpace,
          keyPattern,
          {
            signal: this.loadConfigurationController?.signal,
          }
        );

      if (!merged) return;

      if (merged?.aborted) {
        this.loadLocalizationsController = null;
        return true;
      }

      this.mergedSchema = merged?.schema;

      if (schemaEditable) {
        //load the schema of each parent domain and key pattern
        const sources =
          await this.$store.$coreApi.coreConfigurationApi.getSchemaWithParents(
            this.selectedDomain,
            keySpace,
            keyPattern,
            {
              signal: this.loadConfigurationController?.signal,
            }
          );

        if (sources?.aborted) {
          this.loadLocalizationsController = null;
          return true;
        }

        this.domainSchemas = [];
        let currentDomainSchema = undefined;
        sources
          .sort((s1, s2) => s1.specificity - s2.specificity)
          .forEach((source) => {
            if (source.domainId === this.selectedDomain) {
              currentDomainSchema = source.schema;
            }
            this.domainSchemas.push({
              schema: source.schema,
              domain: source.domainId,
            });
          });

        this.selectedSchema = this.$cloneObject(currentDomainSchema);
        this.savedSchema = this.$cloneObject(currentDomainSchema);
      }

      this.editorKey++;
    },

    async loadData(keySpace, keyPattern, dataEditable) {
      if (!dataEditable) return;
      const mergedData =
        await this.$store.$coreApi.coreConfigurationApi.getMergedData(
          this.selectedDomain,
          keySpace,
          keyPattern,
          this.selectedSchema,
          {
            signal: this.loadConfigurationController?.signal,
            query: "withInheritanceSources=true",
          }
        );

      if (mergedData?.aborted) {
        this.loadLocalizationsController = null;
        return true;
      }

      this.mergedData = mergedData?.data;
      this.sources = mergedData?.sources ?? [];

      //retrieve data of this domain
      const fullData = await this.$store.$coreApi.coreConfigurationApi.getData(
        this.selectedDomain,
        keySpace,
        keyPattern,
        {
          signal: this.loadConfigurationController?.signal,
          includeParents: false,
        }
      );

      if (fullData?.aborted) {
        this.loadLocalizationsController = null;
        return true;
      }

      const currentKeyData = fullData?.[0]?.data;
      //deep clone of data for usage and restore
      this.selectedData = this.$cloneObject(currentKeyData);
      this.savedData = this.$cloneObject(currentKeyData);

      this.editorKey++;
    },

    async saveChanges() {
      if (!this.validate()) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return;
      }
      this.runningAction = true;
      this.violation = null;

      const dataLocation = this.selectedKey.dataLocation;
      const keySpace = this.selectedKeySpace;
      const key = this.selectedKey.name;

      try {
        if (!this.showSchema) {
          //SAVE DATA
          const res = await this.upsertData(
            dataLocation.keySpace,
            dataLocation.keyPattern,
            this.selectedData
          );
          if (res?.status !== 200) {
            if (res?.violation) {
              this.violation = res.violation;
              this.$nextTick(() => this.validate(true));
            }
            return;
          }

          await this.loadData(
            dataLocation.keySpace,
            dataLocation.keyPattern,
            true
          );
        } else {
          //SAVE SCHEMA
          const res = await this.upsertSchemaRule(
            this.selectedKey.id,
            keySpace,
            key,
            dataLocation,
            this.selectedSchema
          );
          if (res?.status !== 200) {
            if (res?.violation) {
              this.violation = res.violation;
              this.$nextTick(() => this.validate(true));
            }
            return;
          }
          await this.loadSchema(keySpace, key, true);
        }
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Error when saving configuration of " +
            this.selectedKey.name +
            "! Cause: " +
            e
        );
      } finally {
        this.runningAction = false;
      }
    },

    async remove() {
      const confirmed = await this.$confirm(
        "Delete configuration?",
        "Are you sure you want to delete the data of " +
          this.selectedKey.name +
          " ?"
      );
      if (!confirmed) return;

      const dataLocation = this.selectedKey.dataLocation;
      this.runningAction = true;

      try {
        if (!this.showSchema) {
          //SAVE DATA
          const res = await this.deleteData(
            dataLocation.keySpace,
            dataLocation.keyPattern
          );
          if (!res?.ok) return;

          this.spaces = null;
          this.configurationTree = [];
          this.loadingKeys = true;
          await this.init();
        }
      } finally {
        this.runningAction = false;
      }
    },

    openAllTreeNodes(openAll) {
      //open/close all tree nodes and increase the treeKey counter
      //so that the tree re-renders, since the trees "open-all" prop
      //is only executed on mount
      this.openAll = openAll;
      this.treeKey++;
    },

    filterTree(item, search, textKey) {
      //check the displayed name and the key pattern if the contain
      //the searched value
      const searchLowerCase = search.toLowerCase();
      const displayedName = item[textKey]?.toLowerCase?.();
      const keyPattern = item?.name?.toLowerCase?.();

      return (
        (displayedName && displayedName.includes(searchLowerCase)) ||
        (keyPattern && keyPattern.includes(searchLowerCase))
      );
    },

    isSelectedKey(item) {
      return item.name === this.selectedKey?.name;
    },

    hasConfiguration(item) {
      return (
        !!item.schema ||
        item.dataEditable ||
        item.schemaEditable ||
        item.keySpace === "ProductType" ||
        item.keySpace === "Cache"
      );
    },

    validate(forceFocus) {
      //Validate all forms which are exists in the data/schema editor
      const forms = document.querySelectorAll(
        ".configuration-editor-container form.v-form"
      );
      const valid = [...forms].every((form) => {
        const vForm = form.__vue__;
        return this.$validateVForm(vForm, forceFocus);
      });
      return valid;
    },

    getExtensionRoute() {
      return this.extensionRoutes.find((routeName) => {
        return this.$route.matched.some(({ name }) => name === routeName);
      });
    },
  },

  computed: {
    keySpaces() {
      return [
        { id: "Configuration", name: "System Configuration" },
        { id: "ProductType", name: "Product Types" },
      ];
    },

    routeName() {
      return this.$route.name;
    },

    hasSelectedKey() {
      return !!this.selectedKey?.id;
    },

    isConfigurationEditor() {
      return this.$route.matched.some(
        ({ name }) => name === "systemConfigEditor"
      );
    },

    isProductTypeEditor() {
      return this.$route.matched.some(
        ({ name }) => name === "productTypeEditor"
      );
    },

    isCacheOverview() {
      return this.$route.matched.some(({ name }) => name === "cache");
    },

    hasViolation() {
      return !!this.violation;
    },

    editorWidth() {
      return this.getPageWidth() - (this.minify ? 80 : 400);
    },

    extensionKeys() {
      return this.$extensions.configuration?.keys ?? [];
    },

    extensionRoutes() {
      return this.extensionKeys.map((extensionKey) => extensionKey.route);
    },

    sortedConfigurationTree() {
      function sortTree(treeNodes) {
        treeNodes.forEach((node) => {
          if (Array.isArray(node.children)) {
            sortTree(node.children);
          }
        });

        treeNodes.sort((node1, node2) => {
          //sort nodes with children before nodes without
          if (node1.children && !node2.children) {
            return -1;
          } else if (node2.children && !node1.children) {
            return 1;
          }
          const name1 = node1.name;
          const name2 = node2.name;
          return name1.localeCompare(name2);
        });
      }

      const tree = this.$cloneObject(this.configurationTree);
      tree.forEach((rootNode) => {
        if (Array.isArray(rootNode.children)) {
          sortTree(rootNode.children);
        }
      });
      return tree;
    },
  },
};
</script>

<style scoped>
.configuration-editor-container {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
}

.configuration-editor-toolbar {
  margin-bottom: 10px;
}

.configuration-editor-container > .configuration-menu {
  position: relative;
}

.basic-editor-container {
  display: flex;
  height: 100%;
  padding: 0;
  flex-flow: column;
}

.basic-editor-container > .v-form {
  height: calc(100% - 64px);
  overflow-y: scroll;
  display: flex;
  flex: 1 0 auto;
}

.basic-editor-container > .v-form::v-deep > .schema-editor.object {
  border: none;
}

.configuration-editor-container .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.configuration-editor-container .loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.configuration-editor-container
  .loading-overlay::v-deep
  .v-overlay--active
  .v-overlay__scrim {
  opacity: 0 !important;
}

.configuration-tree {
  margin-bottom: 48px;
}

.configuration-tree::v-deep .v-treeview-node__label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.0071428571em;
}

.configuration-tree::v-deep .v-treeview-node__label > div.editable {
  cursor: pointer;
}

.configuration-tree::v-deep .v-treeview-node:not(.v-treeview-node--leaf) {
  margin-top: 0px;
}

.configuration-tree::v-deep .v-treeview-node__root,
.configuration-tree::v-deep .v-treeview-node__label,
.configuration-tree::v-deep .v-treeview-node__label > div {
  display: flex;
  align-items: center;
  min-height: 28px;
}

.configuration-tree::v-deep .v-treeview-node__level {
  width: 12px;
}

.configuration-tree::v-deep .v-treeview-node__prepend {
  min-width: 0;
}

.configuration-tree::v-deep .v-treeview-node__content {
  margin-left: 0;
}

.configuration-tree::v-deep .v-treeview-node__children {
  margin-left: 12px;
}
</style>