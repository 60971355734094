<template>
  <MasterDetail
    data-test-id="cacheOverview"
    :detail-open="false"
    :loading="runningAction"
  >
    <template #toolbar="{ loading }">
      <v-toolbar>
        <v-toolbar-title>Caches</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-spacer />
        <ViolationAlert v-if="violation" :violation="violation" class="mr-2" />
        <v-btn
          v-if="items.length > 0"
          text
          title="Invalidate all caches"
          color="primary"
          data-test-id="invalidateAllCachesBtn"
          :disabled="loading"
          @click="invalidate('all')"
        >
          <v-icon left>mdi-broom</v-icon>
          Invalidate all caches
        </v-btn>
      </v-toolbar>
    </template>

    <template #table="{ loading }">
      <v-data-table
        class="cache-table"
        hide-default-footer
        fixed-header
        multi-sort
        single-select
        item-key="id"
        :height="tableHeight"
        :items-per-page="-1"
        :items="items"
        :headers="headers"
        :loading="loading"
      >
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <v-btn
            outlined
            small
            :title="'Invalidate ' + item.name + ' cache'"
            :disabled="loading"
            :data-test-id="'cache_' + item.id + '_invalidateBtn'"
            @click.stop.prevent="invalidate(item.id)"
          >
            <v-icon left>mdi-broom</v-icon>
            Invalidate Cache
          </v-btn>
        </template>
      </v-data-table>
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../common/templates/MasterDetail";
import ViolationAlert from "../common/display-helpers/ViolationAlert";
import mainOverviewMixin from "../../mixins/main-overview-mixin";
export default {
  mixins: [mainOverviewMixin],

  components: {
    MasterDetail,
    ViolationAlert,
  },

  data() {
    return {
      runningAction: false,
      violation: null,
    };
  },

  methods: {
    async invalidate(cache) {
      try {
        this.runningAction = true;
        const res = await this.$store.$coreApi.coreCacheApi.invalidate(
          this.selectedDomain,
          cache,
          {
            returnErrors: true,
          }
        );

        if (!res?.ok) {
          const violation = await res.json();
          this.violation = violation;
          const errorMsg =
            cache === "all"
              ? "Invalidating all caches failed!"
              : "Invalidating " + cache + " cache failed!";
          this.$store.dispatch("setError", errorMsg);
          return;
        }

        this.violation = null;
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    items() {
      return [
        { id: "data", name: "Data" },
        { id: "schema", name: "Schema" },
        { id: "localization", name: "Localization" },
      ];
    },

    headers() {
      return [
        { text: "Caches", value: "name", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
</style>